import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'; // Import slide show styles
import architectureImageData from './architectureImageData.json';
import landscapeImageData from './landscapeImageData.json';
import recentImageData from './recentImageData.json';
import '../App.css'; // Make sure to import your css file

const Gallery = () => {
  const location = useLocation();
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    if (location.pathname.includes('/architecture')) {
      setImageData(architectureImageData);
    } else if (location.pathname.includes('/landscape')) {
      setImageData(landscapeImageData);
    } else if (location.pathname.includes('/recent-project')) {
      setImageData(recentImageData);
    } else {
      setImageData(recentImageData); // Default fallback
    }
  }, [location.pathname]);

  const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px',
    margin: '0.5rem',
  };

  const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>,
  };

  return (
    <div className="gallery-container">
      <Slide easing="ease" autoplay="true" {...properties} duration={3000}>
        {imageData.map((image, index) => (
          <div key={index} className="each-slide">
            <div
              style={{
                backgroundImage: `url(${image.fileName})`,
                backgroundColor: 'black',
              }}
            >
              <div className="slide-caption">
                <h4 className="text-white">{image.title}</h4>
                <p className="text-white">{image.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Gallery;
