import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './views/about';
import MyNavbar from './views/navbar';
import Contact from './views/contact';
import Gallery from './views/Gallery';
import './index.css';
import ImageComparisonGallery from './views/ImageComparisonGallery';

function App() {
  return (
    <div className='App'>
      <Router>
        <MyNavbar />
        <Routes>
          <Route path="/" element={<Gallery />} />
          <Route path="/architecture" element={<Gallery />} />
          <Route path="/transformations" element={<ImageComparisonGallery />} />
          <Route path="/landscape" element={<Gallery />} />
          <Route path="/recent-projects" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
