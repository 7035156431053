import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import '../App.css';

const MyNavbar = () => {
  return (
    <Navbar expand="lg" fixed="top" bg="light" style={{ backgroundColor: 'white' }} variant="light">
      <Link to="/" className="navbar-brand p-0 px-4" style={{ color: '#333' }}>Gnuchev & Partners</Link> {/* Adjusted textDecorationStyle to color */}
      <Navbar.Toggle className="navbar-button" aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="navbar-button" id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Item>
            <Link className="nav-link" to="/landscape" style={{ color: '#333' }}>Landscape Design</Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/recent-projects" style={{ color: '#333' }}>Recent Projects</Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/transformations" style={{ color: '#333' }}>Before & After</Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/architecture" style={{ color: '#333' }}>Architecture</Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/about" style={{ color: '#333' }}>About</Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/contact" style={{ color: '#333' }}>Contact</Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
