import React from 'react';

const About = () => {
  return (
    <div className="container_about">
      <div className="row">
      <div className="col-lg-2"></div>
        <div className="col-lg-8" style={{
                color: 'rgba(0, 0, 0, 0.6)',
                paddingRight: '30px',
                paddingLeft: '30px',
                textAlign: 'justify',
                marginLeft: 'auto',
                marginRight: 'auto',
                }}>
                  <div>
                  <img src='/img/vasily_gnuchev.webp' alt="Vasily Gnuchev" className="rounded" style={{
                height: "240px", paddingTop: '40px',}}/>
                  </div>
          <div>
          <br></br>
<p>Welcome! Our team is dedicated to crafting beautiful and functional landscapes across the San Francisco Bay Area, including San Jose. With our office based in Sunnyvale, CA, our team offers comprehensive Landscape Design services tailored to meet your unique needs and budget.</p>
<p>Our expertise spans from renovating backyards to creating new outdoor living spaces and commercial landscapes. With years of experience in design and construction, we strive to exceed expectations by delivering high-quality work. Contact us to transform your outdoor space into something exceptional.</p>
<p>A bit about me: A Moscow Architectural Institute alumnus, I began my own architectural practice in 2003, working on a variety of projects from resorts to private residences. Since moving to Sunnyvale, CA, in 2021, my focus has shifted to Landscape Design, leveraging my experience to enhance local outdoor spaces.</p>
<p>We collaborate with selected licensed and insured Landscaping Contractors and General Contractors to ensure the highest quality of work.</p>
<h4>Vasily Gnuchev</h4>
<p>Founder of Gnuchev & Partners LLC</p>

          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
};

export default About;



