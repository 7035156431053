function ContactForm() {
  return (
    <form action="https://formspree.io/f/xyyqedbe" method="POST" className="w-full lg:w-3/4 xl:w-1/2 mx-auto" style={{
      color: 'rgba(0, 0, 0, 0.6)',
      textAlign: 'center',
      minWidth: '300px',
      marginTop: '40px',
      marginBottom: '40px',
      backgroundColor: '#fff',
      // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <h4 className="text-2xl font-semibold text-gray-600 text-center mb-8">Get in touch:</h4>
      <div className="mb-4">
        <input 
          type="text" 
          name="name" 
          id="name"
          placeholder="Name"
          required 
          style={{ width: '50%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} // Ensuring the textarea stretches
        />
      </div>
      
      <div className="mb-4">
        <input 
          type="email" 
          name="_replyto"
          id="email"
          placeholder="Email"
          required 
          style={{ width: '50%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} // Ensuring the textarea stretches
        />
      </div>
      
            
      <div className="mb-4">
                <textarea 
                    name="message" 
                    id="message"
                    rows="4"
                    placeholder="Message"
                    required 
                    style={{ width: '50%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} // Ensuring the textarea stretches
                ></textarea>
            </div>
      
      <button 
        type="submit" 
        className="bg-gray-600 px-4 py-2 m-8 rounded-md hover:bg-gray-600 active:bg-gray-1000 focus:outline-none focus:ring focus:ring-gray-200 transition-shadow"
      >
        SEND
      </button>
    </form>
  );
}

export default ContactForm;
